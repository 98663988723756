<template>
  <thead>
    <tr>
      <th
        v-for="(column, index) in filteredColumns"
        :key="index"
        :class="column.hideOnMobile ? 'is-hidden-touch' : ''"
      >
        <span class="filter-menu-dropdown" v-if="column.select">
          <span class="radio-tick">
            <checkbox v-model="toggle" />
          </span>
        </span>
        <span class="header-title" v-if="!column.select">{{ column.label }}</span>
        <a
          v-if="!preview && column.type"
          class="icon"
          :data-test-id="getTestId(column)"
          @click="setSort(column)"
        >
          <i :class="getIcon(column)" />
        </a>
      </th>
    </tr>
  </thead>
</template>

<script>
import { mapGetters } from 'vuex'
import { kebabCase } from 'lodash'
export default {
  name: 'TableHeader',
  data: () => ({ toggle: true }),
  props: {
    sort: {
      type: Object,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    preview: {
      type: Boolean,
      required: false,
      default: () => false
    },
    depth: {
      type: String,
      required: false,
      default: () => ''
    }
  },
  computed: {
    ...mapGetters('auth', ['hasCustomerReference']),
    filteredColumns() {
      if (!this.columns) return [];

      return this.columns.filter((column) => {
        const shouldHideColumn =
          column.hideOnDepth === this.depth ||
          (column.label === 'Reference' && !this.hasCustomerReference)

        return !shouldHideColumn
      })
    }
  },
  watch: {
    toggle(value) {
      return this.$emit('toggle', value)
    }
  },
  methods: {
    setSort({ type }) {
      this.$emit('change', type)
    },
    getIcon({ type }) {
      if (type === this.sort.type) {
        if (this.sort.order === 'asc') {
          return 'fas fa-sort-up'
        } else if (this.sort.order === 'desc') {
          return 'fas fa-sort-down'
        }
        return 'fas fa-sort'
      } else {
        return 'fal fa-sort'
      }
    },
    getTestId({ label }) {
      return kebabCase(label)
    }
  }
}
</script>
<style lang="sass" scoped>
.header-title
  color: $history-table-header-text-color
  font-weight: $history-table-header-font-weight
</style>
