import { render, staticRenderFns } from "./TableHeader.vue?vue&type=template&id=2fc4cdff&scoped=true&"
import script from "./TableHeader.vue?vue&type=script&lang=js&"
export * from "./TableHeader.vue?vue&type=script&lang=js&"
import style0 from "./TableHeader.vue?vue&type=style&index=0&id=2fc4cdff&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../trade-portal/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fc4cdff",
  null
  
)

export default component.exports